.nadialistyourland{
    background-color: #056774; /* Green */
    border: none;
    color: white;
    padding: 7px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
}

.justufytext{
    text-align: justify;
    text-justify: inter-word;
}


.btn-grad44 {
    background-image: linear-gradient(to right, #1ca3b5 0%, #0a6b65  51%, #0a4c78  100%);
    margin: 0px;
    padding: 7px 16px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 0px;
    display: block;
    font-size: 16px;
  }
  
  .btn-grad44:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }